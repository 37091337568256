<template>
    <h1>
        Edit
    </h1>
    <AdminUser />
    <AdminAlbum v-on:isAdmin="isAdmin = $event"/>
    <AdminAlbumDeleted :isAdmin="isAdmin"/>
</template>

<script>
import AdminUser from '../components/Admin_user.vue'
import AdminAlbum from '../components/Admin_album.vue'
import AdminAlbumDeleted from '../components/Admin_album_deleted.vue'

export default {
    components: {
        AdminUser: AdminUser,
        AdminAlbum: AdminAlbum,
        AdminAlbumDeleted: AdminAlbumDeleted
    },
    data(){
        return {
            isAdmin: false,
        }
    },
    methods: {

    }
}
</script>