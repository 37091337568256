<template>
    <table class="userList">
        <tr>
            <td>
                <h2>
                    Album hinzufügen
                </h2>
            </td>
        </tr>
        <tr>
            <td>
                <input type="text" placeholder="Mein Album Name" v-model="album">
            </td>
            <td>
                <button v-on:click="createAlbum">
                    <span class="material-icons-round">
                        create_new_folder
                    </span>
                    <span>
                        Erstellen
                    </span>
                </button>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <table class="userList">
                    <tr
                        v-for="data in albumList"
                        :key="data.id">
                        <td>
                            {{data.title}}
                        </td>
                        <td>
                            <button v-on:click="redirect(data.id)">
                                <span class="material-icons-round">
                                launch
                                </span>
                                <span>
                                    Öffnen
                                </span>
                            </button>
                        </td>
                        <td  v-if="userId !== data.userId && data.userId !== 0">
                            <button>
                                <span class="material-icons-round">
                                account_circle
                                </span>
                                <span>
                                    {{data.user}}
                                </span>
                            </button>
                        </td>
                        <td  v-if="data.userId == 0">
                            <button>
                                <span class="material-icons-round">
                                account_circle
                                </span>
                                <span>
                                    Gelöschter Nutzer
                                </span>
                            </button>
                        </td>
                        <td  v-if="userId === data.userId">
                            <button>
                                <span class="material-icons-round">
                                account_circle
                                </span>
                                <span>
                                    Du bist der Ersteller
                                </span>
                            </button>
                        </td>
                        <td v-if="userId === data.userId || userRight == 'owner' ||  userRight == 'admin'">
                            <button v-on:click="renameAlbum(data.id, data.title)">
                                <span class="material-icons-round">
                                edit
                                </span>
                                <span>
                                    Umbenennen
                                </span>
                            </button>
                        </td>
                        <td v-if="userId === data.userId || userRight == 'owner' ||  userRight == 'admin'">
                            <button v-on:click="deleteAlbum(data.id)">
                                <span class="material-icons-round">
                                delete_forever
                                </span>
                                <span>
                                    Löschen
                                </span>
                            </button>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td>
                <h2>
                    System Informationen
                </h2>
            </td>
        </tr>
        <tr>
            <td>
                <p>
                    Speicher verbraucht
                </p>
            </td>
            <td>
                <p>
                    {{diskUsage}}
                </p>
            </td>
        </tr>
        <tr>
            <td>
                <p>
                    Prozessor Auslastung
                </p>
            </td>
            <td>
                <p>
                    {{cpuUsage}}
                </p>
            </td>
        </tr>
    </table>
</template>

<script>
import axios from 'axios'

export default {
    methods: {
        oncreated(){
            //album list
            axios.get('/api/v1/album/list/')
            .then(response => {
                if(response.data.success === true){
                    this.albumList = [];
                    this.albumList.push(...response.data.value);
                    this.userId = response.data.userId;
                    this.userRight = response.data.right;
                    this.diskUsage = response.data.space;
                    this.cpuUsage = response.data.cpu;

                    this.$emit('isAdmin', (this.userRight == 'admin' || this.userRight == 'owner')?true:false);
                }else{
                    if(response.data.error){
                        console.log(response.data.error);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        createAlbum(){
            let data = new FormData;
            data.append('title', this.album);

            axios.post('/api/v1/album/add/', data)
            .then(response => {
                if(response.data.success === true){
                    this.oncreated();
                    this.album = '';
                }else{
                    if(response.data.error){
                        alert(response.data.error);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        deleteAlbum(id){
            let data = new FormData;
            data.append('id', id);

            axios.post('/api/v1/album/delete/', data)
            .then(response => {
                if(response.data.success === true){
                    this.oncreated();
                    this.album = '';
                }else{
                    if(response.data.error){
                        alert(response.data.error);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        renameAlbum(id, title){
            let newName = prompt('Wähle einen neuen Albumnamen', title);
            if(!newName){
                return;
            }

            let data = new FormData;
            data.append('id', id);
            data.append('newName', newName);

            axios.post('/api/v1/album/rename/', data)
            .then(response => {
                if(response.data.success === true){
                    this.oncreated();
                    this.album = '';
                }else{
                    if(response.data.error){
                        alert(response.data.error);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        redirect(id){
            this.$router.push(`/album/${id}`);
        }
    },
    data(){
        return {
            albumList: [],

            //addUser
            album: '',
            userId: 0,
            userRight: 'user',
            diskUsage: 'Unknow Space usage',
            cpuUsage: 'Unknow Cpu usage'
        }
    },
    created(){
        this.oncreated();
    }
}
</script>

<style scoped>
    table > tr > td > table{
        margin: 0px 0px 0px 30px;
    }
    h2{
        margin: 120px 0px 0px 0px;
    }
    .userList td{
        padding: 8px;
    }
</style>