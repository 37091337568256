<template>
    <table v-if="unfinished.length > 0 || finished.length > 0">
        <tr>
            <td>
                <h2>
                    User hinzufügen
                </h2>
            </td>
        </tr>
        <tr>
            <td>
                <input type="text" placeholder="Merkmal" v-model="note">
            </td>
            <td>
                <select v-model="right">
                    <option value="user">Normal User</option>
                    <option value="admin">Administrator</option>
                    <option value="owner">Owner</option>
                </select>
            </td>
            <td>
                <button v-on:click="addUser">
                    <span class="material-icons-round">
                        person_add
                    </span>
                    <span>
                        Hinzufügen
                    </span>
                </button>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <table class="userList">
                    <tr
                        v-for="data in unfinished"
                        :key="data.id">
                        <td>
                            {{data.note}}
                        </td>
                        <td>

                        </td>
                        <td>
                            {{data.right}}
                        </td>
                        <td>
                            <button v-on:click="copyToken(data.token)">
                                <span class="material-icons-round">
                                content_copy
                                </span>
                                <span>
                                    Einladungs Link Kopieren
                                </span>
                            </button>
                        </td>
                        <td>
                            <button v-on:click="addUserDelete(data.id)">
                                <span class="material-icons-round">
                                person_remove
                                </span>
                                <span>
                                    Entfernen
                                </span>
                            </button>
                        </td>
                    </tr>
                    <tr
                        v-for="data in finished"
                        :key="data.id">
                        <td>
                            {{data.note}}
                        </td>
                        <td>
                            {{data.email}}
                        </td>
                        <td>
                            {{data.right}}
                        </td>
                        <td>
                            
                        </td>
                        <td>
                            <button v-on:click="userDelete(data.id)">
                                <span class="material-icons-round">
                                person_remove
                                </span>
                                <span>
                                    Entfernen
                                </span>
                            </button>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</template>

<script>
import axios from 'axios'

export default {
    methods: {
        addUser(){
            let data = new FormData;
            data.append('right', this.right);
            data.append('note', this.note);
            axios.post('/api/v1/user/register/add/', data)
            .then(response => {
                if(response.data.success === true){
                    alert('User hinzugefügt');
                    this.oncreated();
                }else{
                    if(response.data.error){
                        alert(response.data.error);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        addUserDelete(id){
            let data = new FormData;
            data.append('id', id);
            axios.post('/api/v1/user/register/delete/', data)
            .then(response => {
                if(response.data.success === true){
                    alert('User Invite gelöscht');
                    this.oncreated();
                }else{
                    if(response.data.error){
                        alert(response.data.error);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        userDelete(id){
            let data = new FormData;
            data.append('id', id);
            axios.post('/api/v1/user/delete/', data)
            .then(response => {
                if(response.data.success === true){
                    alert('User  gelöscht');
                    this.oncreated();
                }else{
                    if(response.data.error){
                        alert(response.data.error);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        copyToken(token){
            let domain = window.location.origin;
            let url = `${domain}/#/invite/${token}`;
            window.prompt("Copy to clipboard: Ctrl+C, Enter", url);
        },
        oncreated(){
            this.unfinished = [];
            this.finished = [];

            //unregisterd
            axios.get('/api/v1/user/register/list/')
            .then(response => {
                if(response.data.success === true){
                    this.unfinished.push(...response.data.value);
                }else{
                    if(response.data.error){
                        console.log(response.data.error);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });

            //registerd
            axios.get('/api/v1/user/list/')
            .then(response => {
                if(response.data.success === true){
                    this.finished.push(...response.data.value);
                }else{
                    if(response.data.error){
                        console.log(response.data.error);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    },
    data(){
        return {
            unfinished: [],
            finished: [],

            //addUser
            note: '',
            right: 'user',
        }
    },
    created(){
        this.oncreated();
    }
}
</script>

<style scoped>
    table > tr > td > table{
        margin: 0px 0px 0px 30px;
    }
    h2{
        margin: 120px 0px 0px 0px;
    }
    .userList td{
        padding: 8px;
    }
</style>