<template>
    <table class="userList" v-if="isAdmin === true">
        <tr>
            <td>
                <h2>
                    Gelöschte Alben
                </h2>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <table class="userList">
                    <tr
                        v-for="data in albumList"
                        :key="data.id">
                        <td>
                            {{data.title}}
                        </td>
                        <td>
                            <button>
                                <span class="material-icons-round">
                                launch
                                </span>
                                <span>
                                    Öffnen
                                </span>
                            </button>
                        </td>
                        <td  v-if="data.userId === 0">
                            <button>
                                <span class="material-icons-round">
                                account_circle
                                </span>
                                <span>
                                    User wurde gelöscht
                                </span>
                            </button>
                        </td>
                        <td  v-if="userId === data.userId">
                            <button>
                                <span class="material-icons-round">
                                account_circle
                                </span>
                                <span>
                                    Du bist der Ersteller
                                </span>
                            </button>
                        </td>
                        <td  v-if="userId !== data.userId && data.userId !== 0">
                            <button>
                                <span class="material-icons-round">
                                account_circle
                                </span>
                                <span>
                                    {{data.user}}
                                </span>
                            </button>
                        </td>
                        <td>
                            <button v-on:click="restoreAlbum(data.id, (data.userId == 0)?userId:data.userId)">
                                <span class="material-icons-round">
                                settings_backup_restore
                                </span>
                                <span>
                                    Wiederherstellen
                                </span>
                            </button>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</template>

<script>
import axios from 'axios'

export default {
    methods: {
        oncreated(){
            //album list
            axios.get('/api/v1/album/list/deleted/')
            .then(response => {
                if(response.data.success === true){
                    this.albumList = [];
                    this.albumList.push(...response.data.value);
                    this.userId = response.data.userId;
                }else{
                    if(response.data.error){
                        console.log(response.data.error);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        restoreAlbum(id, userId){
            let data = new FormData;
            data.append('id', id);
            data.append('userId', userId);

            axios.post('/api/v1/album/restore/', data)
            .then(response => {
                if(response.data.success === true){
                    this.oncreated();
                }else{
                    if(response.data.error){
                        alert(response.data.error);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
    },
    data(){
        return {
            albumList: [],
            userId: 0
        }
    },
    props: [
        'isAdmin'
    ],
    created(){
        this.oncreated();
    }
}
</script>

<style scoped>
    table > tr > td > table{
        margin: 0px 0px 0px 30px;
    }
    h2{
        margin: 120px 0px 0px 0px;
    }
    .userList td{
        padding: 8px;
    }
</style>